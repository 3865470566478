/* eslint-disable react/display-name */
import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';

export default ({ data }) => {
  const [aboutMeImage, setAboutMeImage] = useState(
    data.contentfulLindseyContactMeImage.contactImages[0].fluid,
  );

  const [aboutMeImageCounter, setAboutMeImageCounter] = useState(0);

  setTimeout(() => {
    if (
      aboutMeImageCounter >=
      data.contentfulLindseyContactMeImage.contactImages.length
    ) {
      setAboutMeImageCounter(0);
    } else {
      setAboutMeImage(
        data.contentfulLindseyContactMeImage.contactImages[aboutMeImageCounter]
          .fluid,
      );
      setAboutMeImageCounter(aboutMeImageCounter + 1);
    }
  }, 3000);

  return (
    <>
      <div className="contactWrapper">
        <div className="contact__image">
          <Image fluid={aboutMeImage}></Image>
        </div>
        {/* <h1 className="contact__Header"> Contact </h1> */}
        <div className="contact__body">
          <span>David Foster Management</span>
          <br />
          <br />
          Call: <a href="tel:+441264771726">+44 (0)1264 771726</a>
          <br />
          <br />
          Email:
          <a href="mailto:david@dfmanagement.tv"> david@dfmanagement.tv</a>
          <br />
          <br />
          Website:{' '}
          <a href="https://www.dfmanagement.tv/lindsey-chapman/" target="blank">
            www.dfmanagement.tv
          </a>{' '}
          <br />
          <br />
          <a href="mailto:lindsey@lindseychapman.co.uk">Email Lindsey</a>
          <br />
          <br />
          If you would like to write to Lindsey, please send your messages to:
          <br />
          <br />
          FAO: Lindsey Chapman
          <br />
          David Foster Management
          <br />
          PO Box 1805
          <br />
          Andover
          <br />
          Hants
          <br />
          SP10 3ZN
          <br />
          <br />
          <Link to="/">Back to home</Link>
        </div>
        <div className="contact__left"></div>
        <div className="contact__right"></div>
      </div>
    </>
  );
};

export const pageQuery = graphql`
  query {
    contentfulLindseyContactMeImage {
      contactImages {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;
